
import Vue from 'vue';
import { mapGetters } from 'vuex';

import Account from 'Entities/privatePresenter/Account';
import Group from 'Entities/privatePresenter/Group';
import AccountColorMarker from 'Common/AccountColorMarker.vue';
import Icon from 'UI/Icon.vue';
import Button from 'Control/Button.vue';
import NoData from 'UI/NoData.vue';

type Data = {
    activeTabIndex: number;
    tabs: string[];
    cancelableItem: Account | Group | null;
};

type Methods = {
    getAccountsColor: (data: Account) => string;
    openModal: (data: Account | Group) => void;
};

type Computed = {
    isThemeDark: boolean;
    ownedAccountsList: Account[];
    managedAccountsList: Account[];
    institutionalList: Group[];
    activeList: Account[] | Group[];
};

export default Vue.extend<Data, Methods, Computed>({
    components: { NoData, Button, Icon, AccountColorMarker },
    data() {
        return {
            activeTabIndex: 0,
            tabs: [
                'Owned Accounts',
                'Managed Accounts',
                'Institutional Groups',
            ],
            cancelableItem: null,
        };
    },
    computed: {
        ...mapGetters({
            isThemeDark: 'isThemeDark',
        }),
        ownedAccountsList() {
            const accounts: Account[] = this.$store.getters['Accounts/ownAccounts'];
            accounts.sort(({ name: a }, { name: b }) => {
                if (a === 'main') {
                    return -1;
                }
                if (b === 'main') {
                    return 1;
                }
                return 0;
            });
            return accounts;
        },
        managedAccountsList() {
            return this.$store.getters['Accounts/manageAccounts'];
        },
        institutionalList() {
            return this.$store.state.Groups.groups;
        },
        activeList() {
            switch (this.activeTabIndex) {
                case 0: {
                    return this.ownedAccountsList;
                }
                case 1: {
                    return this.managedAccountsList;
                }
                case 2: {
                    return this.institutionalList;
                }
                default: {
                    return this.ownedAccountsList;
                }
            }
        },
    },
    methods: {
        getAccountsColor(account: Account) {
            if (!account || !account.color) {
                return this.isThemeDark ? '#23232A' : '#f1f2f5';
            }
            return account.color;
        },
        openModal(item) {
            this.cancelableItem = item;
            this.$modal.show('cancelModal');
        },
    },
    mounted() {
        const { query: { tab } } = this.$route;
        if (tab) {
            this.activeTabIndex = Number(tab);
        }
    },
});
